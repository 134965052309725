<template>
  <div class="related collections">
    <header>
      <h2>
        <nuxt-link :to="`/watch?genre=${data.title.toLowerCase()}`">
          {{ data.title }}
        </nuxt-link>
      </h2>
    </header>
    <slider-component :videos="data.items" />
  </div>
</template>

<script>
export default {
  props: { data: { type: Object, required: true, default: () => ({})  } },
  components: {
    sliderComponent: () => import('~/components/sliderComponent.vue')
  }
}
</script>
